import React from 'react'
import theme from 'design'
import { makeIcon } from 'design/components/Icons/Icons'
import { TwoColorIconDefinition } from 'design/components/Icons/types'

export const def: TwoColorIconDefinition = (
  colors = { base: 'currentColor', accent: 'currentColor' }
) => {
  return {
    id: 'blankCardBack',
    name: 'Black Card Back',
    category: 'payment',
    path: (
      <>
        <path
          clipRule="evenodd"
          d="M0 3a3 3 0 013-3h26a3 3 0 013 3v14a3 3 0 01-3 3H3a3 3 0 01-3-3V3z"
          fill={colors.base}
          fillRule="evenodd"
        />
        <path
          d="M0 4.348h32v3.043H0zM2.424 10.435h16v2.174h-16zM2.424 13.913h11.152v2.174H2.424zM23.273 10.87h4.848v3.043h-4.848z"
          fill={colors.accent}
        />
      </>
    ),
    viewBox: '0 0 32 20',
  }
}

export const IconCcBlankCardBack = makeIcon(
  def({ base: theme.colors.bb.ivory, accent: theme.colors.bb.silt })
)
export default IconCcBlankCardBack
